import React from "react"
import axios from "axios"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"

function ContactMarketingPage() {
  const [formValues, setformValues] = React.useState({
    fullName: "",
    email: "",
    organization: "",
    interest: "",
    currentlyOffering: "",
    message: "",
  })
  const [showConfirmation, setShowConfirmation] = React.useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault()
    
    const contactFormData = {
      fullName: formValues.fullName,
      email: formValues.email,
      organization: formValues.organization,
      interest: formValues.interest,
      currentlyOffering: formValues.currentlyOffering,
      message: formValues.message,
    }

    try {
      await axios({
        method: "post",
        url: `${process.env.GATSBY_LATNERN_REST_URL}/contactMartketing`,
        data: contactFormData,
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        setShowConfirmation(true)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleChange = (event) => {
    setformValues({
      ...formValues,
      [event.target.name]: event.target.value,
    })
  }

  return (
    <Layout>
      <SEO
        keywords={["Lantern", "contact"]}
        title="Contact"
        description="Contact Lantern."
        excludeSiteMetadataTitle
      />
      <div className="bg-grey-lighter">
        <div className="max-w-body mx-auto pt-16 md:pt-24 px-4 xl:px-0">
          {!showConfirmation && 
            <div className="headline-text text-center">
              Bring Lantern to your company for free.
            </div>}

          {showConfirmation && (
            <div className="sub-headline-text leading-relaxed text-center pt-8">
              Thank you for reaching out. We will be in touch soon!
            </div>
          )}

          <section className="md:flex md:items-center py-16">
            {!showConfirmation && (
              <form
                className="mx-auto lg:w-1/2"
                name="marketing"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
              >
                <input type="hidden" name="form-name" value="marketing" />
                <div className="flex flex-wrap">
                  <div className="w-full">
                    <label
                      className="contact text-grey-dark text-sm font-semibold w-full"
                      htmlFor="fullName"
                    >
                      <p>Full Name</p>
                    </label>
                    <input
                      className="default-form-field w-full mb-4 py-3 border-grey"
                      id="fullName"
                      name="fullName"
                      placeholder="Jane"
                      value={formValues.fullName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-full">
                    <label
                      className="contact text-grey-dark text-sm font-semibold w-full"
                      htmlFor="email"
                    >
                      <p>Email Address</p>
                    </label>
                    <input
                      className="default-form-field w-full mb-4 py-3 border-grey"
                      id="email"
                      name="email"
                      placeholder="yourname@website.com"
                      type="email"
                      value={formValues.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-full">
                    <label
                      className="contact text-grey-dark text-sm font-semibold w-full"
                      htmlFor="organization"
                    >
                      <p>Organization</p>
                    </label>
                    <input
                      className="default-form-field w-full mb-4 py-3 border-grey"
                      id="organization"
                      name="organization"
                      value={formValues.organization}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-full">
                    <label
                      className="contact text-grey-dark text-sm font-semibold w-full"
                      htmlFor="interest"
                    >
                      <p>
                        Are you interested in{" "}
                        <a
                          href="https://lantern.co/work"
                          target="undefined"
                          rel="noopener noreferrer"
                        >
                          Lantern For Work
                        </a>
                        ,{" "}
                        <a
                          href="https://lantern.co/business"
                          target="undefined"
                          rel="noopener noreferrer"
                        >
                          Lantern For Business
                        </a>
                        , or{" "}
                        <a
                          href="https://lantern.co/deathatwork"
                          target="undefined"
                          rel="noopener noreferrer"
                        >
                          Grief in the Workplace Workshops
                        </a>
                        ?
                      </p>
                      <input
                        className="default-form-field w-full mb-4 py-3 border-grey"
                        id="interest"
                        name="interest"
                        value={formValues.interest}
                        onChange={handleChange}
                      />
                    </label>
                  </div>
                  <div className="w-full">
                    <label
                      className="contact text-grey-dark text-sm font-semibold w-full"
                      htmlFor="currentlyOffering"
                    >
                      <p>
                        Do you currently offer end-of-life planning or grief
                        related resources?
                      </p>
                    </label>
                    <input
                      className="default-form-field w-full mb-4 py-3 border-grey"
                      id="currentlyOffering"
                      name="currentlyOffering"
                      placeholder=""
                      type="text"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-full">
                    <label
                      className="contact text-grey-dark text-sm font-semibold w-full"
                      htmlFor="message"
                    >
                      <p>Is there anything else we should know?</p>
                    </label>
                    <textarea
                      className="default-form-field w-full mb-4 py-3 border-grey"
                      id="message"
                      name="message"
                      placeholder="Explain here..."
                      rows="8"
                      value={formValues.message}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>

                <div className="md:flex">
                  <Button
                    buttonText="Send"
                    buttonType="btn in-app -primary"
                    additionalClasses="ml-0"
                    eventName={{
                      eventDomain: "App",
                      eventLocation: "Marketing Contact Form",
                      eventAction: "Click",
                      eventActionName: "Send",
                    }}
                  />
                </div>

                <div className="tracking-normal leading-normal font-normal text-sm pb-2 text-grey-darker text-left pt-4">
                  <p>
                    For press inquiries, email{" "}
                    <strong>
                      <a
                        href="mailto:media@lantern.co"
                        target="undefined"
                        rel="noopener noreferrer"
                      >
                        media@lantern.co
                      </a>
                    </strong>
                  </p>
                </div>
              </form>
            )}
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default ContactMarketingPage
